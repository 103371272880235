<template>
  <b-container class="current-chat-container">
    <b-container class="chat-header-container" fluid>
      <b-row id="msgOptions">
        <b-col cols="1" class="backToMessagesBtnOutter">
          <router-link id="goToMessages" :to="{
            name: 'Messages',
          }">
            <button class="backToMessagesBtn">
              <i class="fas fa-arrow-left"></i>
            </button>
          </router-link>
        </b-col>
        <b-col cols="6" class="searchSection">
          <b-form-input id="searchMsg" v-model="search" placeholder="Search in the messages" type="text"
            debounce="500"></b-form-input>
            <i class="fas fa-search" id="searchIcon"></i>
        </b-col>
        <b-col cols="1" class="option-icon">
          <i @click="modalDeleteConversation" class="fa fa-trash" aria-hidden="true" v-b-tooltip.hover
            title="Delete Conversation"></i></b-col>
        <b-col cols="1" class="option-icon">
          <i @click="modalForHideChat" class="fa fa-archive" aria-hidden="true" v-b-tooltip.hover
            title="Hide Conversation"></i></b-col>
        <b-col cols="1" class="option-icon">
          <i class="fa fa-ban" aria-hidden="true" @click="modalShow = !modalShow" v-b-tooltip.hover
            title="Block User"></i></b-col>
        <b-col cols="1" class="option-icon">
          <i class="fa fa-thumb-tack" aria-hidden="true" @click="pinMsg" v-b-tooltip.hover
            title="Pin/Unpin Conversation"></i>
        </b-col>

        <b-col cols="1"  :class="`dropdown-section-outter ${isDropped ? 'active' : ''}`">
          <div id="dropdown-section" v-b-tooltip.hover title="More">
            <button class="dropdownLink" @click="dropIt">
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle" aria-hidden="true"></i>
              <i class="fa fa-circle" aria-hidden="true"></i>
            </button>
            <transition name="slide">
              <ul class="list" v-if="isDropped">
                <li>
                  <router-link id="blacklist" :to="{
            name: 'blacklist',
          }">
                    Manage Blacklist
                  </router-link>
                </li>
              </ul>
            </transition>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <notifications width="30%" position="top center" />

    <div id="currentChat" ref="chatContainer">
      <b-container v-if="!userDataFromParams &&
            chatDetails.receiver &&
            chatDetails.receiver.customer
            " class="mt-2 mb-2">
        <b-row class="chat-information">
          <b-col cols="1">
            <img id="userProfilePic" :src="chatDetails.receiver.customer.profile_picture" />
          </b-col>
          <b-col class="dataCol" cols="11">
            <p style="color: #333333; font-weight: bold" v-if="chatDetails.receiver">
              {{ chatDetails.receiver.first_name }}
              {{ chatDetails.receiver.last_name }}
            </p>
            <p v-if="chatDetails.receiver.customer">
              {{ chatDetails.receiver.customer.job_title }}
            </p>
          </b-col>
        </b-row></b-container>
      <div v-if="currentConversation.length">
        <b-container fluid class="messages" v-for="(message, index) in currentConversation" :key="$lodash.get(message, 'id')">
          <div class="conversation-time" v-if="isMoreThanAday(message, currentConversation[index - 1])">
            <span class="time">{{ showTimeSince((message.created_at)) }}</span>
          </div>
          <b-row no-gutters :class="`messageRight ${isFirstOfOwnerMessage(message, currentConversation[index - 1]) ? 'myMsg' : ''}`" v-if="message && $lodash.get(message, 'user_id') === ownerId">
            <b-col>
              <p class="msgTime mr-2" v-if="isFirstOfOwnerMessage(message, currentConversation[index - 1])">
                {{
            moment($lodash.get(message, "created_at")).format("HH:mm")
          }}
              </p>
              <div v-if="isPostLink($lodash.get(message, 'content'))" class="newMsg p-0">
                <PostPreview  :postId="getPostIdFromMessage($lodash.get(message, 'content'))" />
              </div>
              <a class="link-msg" v-else-if="$lodash.get(message, 'content').startsWith('http') ||
            $lodash.get(message, 'content').startsWith('www.')
            " :href="$lodash.get(message, 'content')">{{ $lodash.get(message, "content") }}</a>
              <div v-else class="newMsg">
                {{ $lodash.get(message, "content") }}
              </div>
              <div class="seenMessage" v-if="isLastOfOwnerMessage(message, currentConversation[index+1]) && $lodash.get(message, 'is_viewed') === 1">
                <i class="fa fa-check"></i>
              </div>
            </b-col>
          </b-row>

          <b-row v-else no-gutters>
            <b-col cols="1 avatarCol" :class="`${!isFirstOfOtherMessage(message, currentConversation[index - 1]) ? 'hidden': ''}`">
              <img v-if="chatDetails.receiver && chatDetails.receiver.customer" id="userProfilePic"
                :src="chatDetails.receiver.customer.profile_picture" />
            </b-col>
            <b-col cols="11 messageCol" class="receiverData" v-if="chatDetails.receiver && message">
              <p class="msgTime" v-if="isFirstOfOtherMessage(message, currentConversation[index - 1])">
                {{ chatDetails.receiver.first_name }}
                {{ chatDetails.receiver.last_name }}
                {{
            moment($lodash.get(message, "created_at")).format("HH:mm")
          }}
                <span v-if="chatDetails.receiver && chatDetails.receiver.is_online" class="online"></span>
              </p>
              <p  :class="`received p-0 ${isFirstOfOtherMessage(message, currentConversation[index - 1]) ? 'first' : ''} `" v-if="isPostLink($lodash.get(message, 'content'))">
                <PostPreview leftLayout :postId="getPostIdFromMessage($lodash.get(message, 'content'))" />
              </p>
              <a :class="`link-msg-received ${isFirstOfOtherMessage(message, currentConversation[index - 1]) ? 'first' : ''} `" v-else-if="$lodash.get(message, 'content').startsWith('http') ||
            $lodash.get(message, 'content').startsWith('www.')
            " :href="$lodash.get(message, 'content')">{{ $lodash.get(message, "content") }}</a>
              <p v-else :class="`received ${isFirstOfOtherMessage(message, currentConversation[index - 1]) ? 'first' : ''} `">
                {{ $lodash.get(message, "content") }}
              </p>
            </b-col>
          </b-row>
        </b-container>
        <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler" direction="top" :identifier="conversationInfiniteId">
          <div slot="spinner">Loading...</div>
          <div slot="no-results"></div>
          <div slot="no-more"></div>
        </infinite-loading>
      </div>

      <b-container v-else class="startNewSession" style="margin-top: 12px">
        <b-row v-if="userDataFromParams">
          <b-col cols="1" v-if="userDataFromParams.customer">
            <img id="userProfilePic" :src="userDataFromParams.customer.profile_picture" />
          </b-col>
          <b-col class="dataCol" cols="11">
            <p style="color: #333333">
              {{ userDataFromParams.first_name }}
              {{ userDataFromParams.last_name }}
            </p>
            <p style="color: #aaaaaa">
              Start a new messaging session with
              {{ userDataFromParams.first_name }} typing in the bar bellow.
            </p>
          </b-col>
        </b-row>
      </b-container>

      <p class="user-typing-info" v-if="showTyping">
        {{ $lodash.get(chatDetails, "receiver.first_name") }} is typing...
      </p>
    </div>
    <b-container class="messageInput">
      <b-form-textarea class="msg-input" autofocus v-model="newMessage" no-resize
        @keydown.enter.prevent.exact="writeMessage" @keyup.ctrl.enter="enterNewLine"
        placeholder="Type your message here"></b-form-textarea>
      <button @click="writeMessage" class="sendBtn">
        Send
        <i class="fas fa-paper-plane sendIcon"></i>
      </button>

      <div class="wrapper">
        <emoji-picker @emoji="append" :search="search">
          <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent" title="Emoji">
            <i class="fas fa-grin"></i>
          </div>
          <div slot="emoji-picker" slot-scope="{ emojis, insert }">
            <div class="emoji-picker">
              <div>
                <div v-for="(emojiGroup, category) in emojis" :key="category">
                  <h5>{{ category }}</h5>
                  <div class="emojis">
                    <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)"
                      :title="emojiName">{{ emoji }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </emoji-picker>
      </div>
    </b-container>
    <b-modal id="blockModal" hide-footer v-model="modalShow" title="Add Member to Blacklist">
      <div id="blockModalBody">
        <h5>Are you sure you want to add current member to your
          Blacklist?</h5>
        <div class="modal-button-wrapper">
          <b-button @click="blocked" class="mt-3" variant="outline-success" block>Block</b-button>
          <b-button @click="modalShow = !modalShow" class="mt-3" variant="outline-danger" block>Cancel</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-hide-chat" hide-footer title="Do you want to hide the current chat?">
      <div id="blockModalBody">
        <h5>Are you sure you want to hide the current chat?</h5>
        <div class="modal-button-wrapper">
          <b-button block @click="hideChat" class="mt-3" variant="outline-success">Hide </b-button>
          <b-button block @click="hideModal" class="mt-3 cancel" variant="outline-danger">Cancel</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-remove-chat" title="Delete Messages" hide-footer>
      <div id="blockModalBody">
      <h5>Are you sure you want to delete all messages received until now from current member?</h5>
          <div class="modal-button-wrapper">
          <b-button class="mt-3" variant="outline-success"  @click="deleteChat">Delete </b-button>
          <b-button class="mt-3 cancel" variant="outline-danger" @click="hideModal">Cancel</b-button>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import EmojiPicker from 'vue-emoji-picker';
import SocketConnect from '../../../utils/socket';
import PostPreview from './PostPreview.vue';

import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    EmojiPicker,
    PostPreview,
  },
  data() {
    return {
      visible: true,
      search: '',
      currentTime: null,
      newMessage: '',
      isHidden: true,
      modalShow: false,
      token: '',
      ENDPOINT: '',
      userDataFromParams: null,
      conversationId: null,
      chatDetails: {},
      ownerId: null,
      isDropped: false,
      page: 1,
      conversationInfiniteId: uuidv4(),
    };
  },
  computed: {
    ...mapGetters({
      currentConversation: 'messages/currentConversation',
      userTypingData: 'messages/userTypingData',
      socketError: 'socketError',
    }),
    apiHost() {
      return `${process.env.VUE_APP_API_HOST}`;
    },
    showTyping() {
      const isTyping = this.userTypingData
        && this.userTypingData.user_id === this.chatDetails.receiver_id;
      if (isTyping) {
        this.$nextTick(() => {
          const chatbox = this.$refs.chatContainer;
          chatbox.scrollTop = chatbox.scrollHeight;
        });
      }
      return isTyping;
    },
  },
  async mounted() {
    this.initChatAndLoadMessages();
    window.onfocus = () => {
      this.page = 1;
      this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset'); // reset the infinite loading
      this.initChatAndLoadMessages();
      const socket = new SocketConnect();
      socket.connect();
    };
  },
  beforeDestroy() {
    window.onfocus = null;
  },
  methods: {
    ...mapActions({
      getCurrentConversation: 'messages/getCurrentConversation',
      getCurrentChatDetails: 'messages/getCurrentChatDetails',
      searchConversationMessages: 'messages/searchConversationMessages',
      pinMessageToTop: 'messages/pinMessageToTop',
      unPinMessage: 'messages/unPinMessage',
      blockConversation: 'messages/blockConversation',
      unblockConversation: 'messages/unblockConversation',
      hideConversation: 'messages/hideConversation',
      setStartTyping: 'messages/setStartTyping',
      setEndTyping: 'messages/setEndTyping',
      setAsSeenMessage: 'messages/setAsSeenMessage',
      sendMessageViaNodeJSServer: 'messages/sendMessageViaNodeJSServer',
      sendMessageToCurrentUser: 'messages/sendMessageToCurrentUser',
      removeConversation: 'messages/removeConversation',
    }),
    async infiniteHandler($state) {
      try {
        this.page += 1;
        await this.getCurrentConversation({
          chatRoomId: this.$route.params.chatRoomId,
          page: this.page,
        }).then(({ result }) => {
          if (result.docs.length) {
            $state.loaded();
          } else $state.complete();
        });
      } catch (err) {
        $state.complete();
      }
    },
    enterNewLine() {
      this.newMessage += '\n';
    },
    append(emoji) {
      this.newMessage += emoji;
    },
    dropIt() {
      this.isDropped = !this.isDropped;
    },
    moment(date) {
      return moment(new Date(date));
    },
    updateCurrentTime() {
      this.currentTime = moment().format('HH:mm');
    },
    setAsReadMessage() {
      const data = {
        chats: [this.$route.params.chatRoomId],
      };
      this.setAsSeenMessage(data);
    },
    setUserTypingStatus(msg) {
      const data = {
        chat_room_id: this.chatDetails.chat_room_id,
        receiver_id: this.chatDetails.receiver_id,
      };
      if (msg) {
        this.setStartTyping(data);
      } else {
        this.setEndTyping(data);
      }
    },
    async writeMessage() {
      try {
        const regexp = /^\s*$/;
        if (!regexp.test(this.newMessage)) {
          const messageData = {
            chat_room_id: this.chatDetails.chat_room_id,
            content: this.newMessage,
            receiver_id: this.chatDetails.receiver_id,
            is_online_receiver: this.chatDetails.receiver.is_online,
          };
          await this.sendMessageViaNodeJSServer(messageData);
          this.sendMessageToCurrentUser({
            "content": this.newMessage,
            "created_at": new Date().toISOString(),
            "updated_at": new Date().toISOString(),
            "is_viewed": 0,
            "user_id": this.chatDetails.owner_id,
            "chat_room_id": this.chatDetails.chat_room_id,
            "attachment": null,
            "is_edited": 0
          });
          this.newMessage = '';
        }
      } catch (err) {
        this.$notify({
          group: 'userAlerts',
          title: 'Warning',
          text: err,
          type: 'warn',
        });
      }
    },
    blockModal() {
      this.$refs['my-modal'].show();
    },
    blocked() {
      try {
        this.blockConversation({ chats: [this.conversationId] });
        this.$router.go(-1);
      } catch (err) {
        console.log(err);
      }
    },
    modalForHideChat() {
      this.$refs['modal-hide-chat'].show();
    },
    hideModal() {
      this.$refs['modal-hide-chat'].hide();
      this.$refs['modal-remove-chat'].hide();
    },
    hideChat() {
      try {
        this.hideConversation(this.chatDetails.id);
        this.modalShow = !this.modalShow;
        this.$router.go(-1);
      } catch (err) {
        console.log(err);
      }
    },
    deleteChat() {
      try {
        this.removeConversation({  chatConversations: [this.chatDetails.chat_room_id] })
        this.$refs['modal-remove-chat'].hide();
        this.$router.go(-1);
      } catch (err) {
        console.log(err);
      }
    },
    pinMsg() {
      try {
        if (this.chatDetails.sorting_point === 0) {
          this.pinMessageToTop({ chats: [this.conversationId] });
          this.$notify({
            type: 'success',
            text: 'Conversation successfully pinned to the top',
          });
        } else {
          this.unPinMessage({ chats: [this.conversationId] });
          this.$notify({
            type: 'success',
            text: 'Successfully unpined!',
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async initChatAndLoadMessages() {
      try {
        this.userDataFromParams = this.$route.params.user;
        const { result } = await this.getCurrentConversation({
          chatRoomId: this.$route.params.chatRoomId,
          page: this.page,
        });
        this.conversationId = result.id;

        const data = await this.getCurrentChatDetails(this.conversationId);
        this.chatDetails = data.result;
        this.ownerId = this.chatDetails.owner_id;
        this.$nextTick(() => {
          const chatbox = this.$refs.chatContainer;
          chatbox.scrollTop = chatbox.scrollHeight;
        });
        this.setAsReadMessage();
      } catch (err) {
        console.log(err);
      }
    },
    isOwnerMessage(message) {
      return this.$lodash.get(message, 'user_id') === this.ownerId;
    },
    isFirstOfOwnerMessage(currentMessage, previousMessage) {
      return (this.isOwnerMessage(currentMessage) && !this.isOwnerMessage(previousMessage)) || this.isMoreThanAday(currentMessage, previousMessage);
    },
    isFirstOfOtherMessage(currentMessage, previousMessage) {
      return (!this.isOwnerMessage(currentMessage) && (this.isOwnerMessage(previousMessage) 
        || !previousMessage)) || this.isMoreThanAday(currentMessage, previousMessage);
    },
    isLastOfOtherMessage(currentMessage, nextMessage) {
      return (!this.isOwnerMessage(currentMessage) && this.isOwnerMessage(nextMessage));
    },
    isLastOfOwnerMessage(currentMessage, nextMessage) {
      return (this.isOwnerMessage(currentMessage) && !this.isOwnerMessage(nextMessage));
    },
    isMoreThanAday(latestMessage, previousMessage) {
      if (previousMessage) {
        return moment(latestMessage.created_at).diff(previousMessage.created_at, 'days') > 0;
      }

      return false;
    },
    showTimeSince(date) {
      // return today if the date is today
      if (moment().isSame(date, 'day')) {
        return 'Today';
      }

      if (moment().subtract(1, 'days').isSame(date, 'day')) {
        return 'Yesterday';
      }

      return moment(date).format('MMM DD, YYYY');
    },
    modalDeleteConversation() {
      this.$refs['modal-remove-chat'].show();
    },
    isPostLink(message) {
      return message && message.includes('personal-wall/post');
    },
    getPostIdFromMessage(message) {
      return message.split('personal-wall/post/')[1];
    },
  },
  watch: {
    search: {
      async handler() {
        await this.searchConversationMessages({
          chatRoomId: this.$route.params.chatRoomId,
          searchValue: this.search,
        });
      },
    },
    currentConversation: {
      handler() {
        this.$nextTick(() => {
          const chatbox = this.$refs.chatContainer;
          chatbox.scrollTop = chatbox.scrollHeight;
        });
      },
    },
    newMessage: {
      handler(msg) {
        this.setUserTypingStatus(msg);
      },
    },
    socketError: {
      handler(err) {
        if (err.message) {
          this.$notify({
            type: 'error',
            text: err.message,
          });
        }
      },
    },
  },
};
</script>

<style src="./CurrentMessage.css" scoped></style>
